import React, {useState} from "react"
import useContactForm from './use-contact-form'
import validate from "./validate-contact-form";
import {FormattedMessage, useIntl} from "react-intl";
import PlaceholderText from "./get-placeholder-text.component";

const ContactFormComponent = () => {
    const [showForm, setShowForm] = useState(true)
    const {
        values,
        errors,
        handleChange,
        handleSubmit,
        isSubmitting
    } = useContactForm(sendFeedback, validate);

    const cantBeSubmitted = errors.name || errors.email || errors.message

    function sendFeedback() {
        setShowForm(false)
    }

    // const email = intl.formatMessage({id: 'email'})
    return (
        <div className="contactFormHolder">
            {showForm && (
                <form
                    onSubmit={handleSubmit}
                    noValidate
                >
                    <div className={errors.name ? "formGroup hasError" : "formGroup"}>
                        <input
                            autoComplete="off"
                            type="text"
                            className="form-control"
                            placeholder={PlaceholderText('name')}
                            name='name'
                            onChange={handleChange}
                            required
                            value={values.name || ''}
                        />
                        {errors.name && (
                            <>
                                <div className="errorBtn">
                                    {/*<img src={ErrorIcon} alt=""/>*/}
                                </div>
                                <div className="errorMessage">{PlaceholderText(errors.name)}</div>
                            </>
                        )}
                    </div>
                    <div className={errors.email ? "formGroup hasError" : "formGroup"}>
                        <input
                            autoComplete="off"
                            type="email"
                            className="form-control"
                            placeholder={PlaceholderText('email')}
                            name='email'
                            onChange={handleChange}
                            required
                            value={values.email || ''}
                        />
                        {errors.email && (
                            <>
                                <div className="errorBtn">
                                    {/*<img src={ErrorIcon} alt=""/>*/}
                                </div>
                                <div className="errorMessage">{PlaceholderText(errors.email)}</div>
                            </>
                        )}

                    </div>
                    <div className={errors.message ? "formGroup hasError" : "formGroup"}>
                        <textarea
                            className="form-control"
                            placeholder={PlaceholderText('message')}
                            rows='6'
                            name='message'
                            onChange={handleChange}
                            required
                            value={values.message || ''}
                        />
                        {errors.message && (
                            <>
                                <div className="errorBtn">
                                    {/*<img src={ErrorIcon} alt=""/>*/}
                                </div>
                                <div className="errorMessage">{PlaceholderText(errors.message)}</div>
                            </>
                        )}
                    </div>
                    <input type="text" name="_gotcha" style={{display: "none"}} />
                    <div className="btnHolder">
                        {isSubmitting ?
                            (
                                <div className='loaderHolder'>
                                    <div className='loader-md'/>
                                </div>
                            ) :
                            (
                                <button type="submit" className="btn btnPrimary contained" disabled={cantBeSubmitted}>
                                    <FormattedMessage id='send' defaultMessage='Send'/>
                                </button>
                            )
                        }
                    </div>
                </form>
            )}
            {!showForm && (
                <div className="formSuccessMessage">
                    <FormattedMessage id='message-success' defaultMessage='We’ve got the message and will reply to you soon 🙂'/>
                </div>
            )}
        </div>
    )
}

export default ContactFormComponent