import React, {useEffect, useState} from 'react'
import MenuBtn from "../../../assets/img/menu-icon.svg";
import CloseBtn from '../../../assets/img/close-icon.svg'
import {Link} from "gatsby";
import {CSSTransition} from 'react-transition-group';
import Scroll, { Link as ScrollLink } from "react-scroll";
import Logo from "../../../assets/img/logo_leads.svg";
import FlagEn from "../../../assets/img/flag-english.svg"
import FlagUa from "../../../assets/img/flag-uk.svg"
// import FlagRu from "../../../assets/img/flag-ru.svg"
import {FormattedMessage} from "react-intl";

let Events = Scroll.Events;

const MenuComponent = () => {
    Events.scrollEvent.register('begin', function(to, element) {
        handleCloseMenu()
    });
    const [menuOpen, setMenuOpen] = useState(false);
    const handleCloseMenu = () => {
        if (menuOpen) {
            setMenuOpen(false);
        }
    }
    useEffect(() => {
        const bodyTag = document.querySelector('body');
        if (bodyTag) {
            menuOpen ? bodyTag.classList.add('menuOpen') : bodyTag.classList.remove('menuOpen')

        }
    })
    return (
        <div className="mainMenuHolder">
            <div className="menuBtn">
                <img src={MenuBtn} alt="Menu" onClick={() => setMenuOpen(true)}/>
            </div>
            <CSSTransition
                in={menuOpen}
                timeout={300}
                unmountOnExit
                classNames="mobile-menu"
            >
                <div className="mainMenuContainer">
                    <div className="mainMenuHeader">
                        <div className="mainContainer fluid">
                            <div className="mainMenuHeaderWrapper">
                                <Link to={'/'}><img src={Logo} alt="Huboxt" className='logoImg'/></Link>
                                <div className="closeBtn">
                                    <img src={CloseBtn} alt="Menu Close" id="menuTrigger" onClick={() => setMenuOpen(false)}/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="mainMenuListHolder">
                        <ul className="mainMenuList">
                            <li className="mainMenuListItem"><ScrollLink to="aboutSlide" smooth={true} offset={120}><FormattedMessage id='about' defaultMessage='About'/></ScrollLink></li>
                            <li className="mainMenuListItem"><ScrollLink to="keySlide" smooth={true}><FormattedMessage id='key-features' defaultMessage='Key features'/></ScrollLink></li>
                            <li className="mainMenuListItem"><ScrollLink to="priceSlide" smooth={true}><FormattedMessage id='pricing' defaultMessage='Pricing'/></ScrollLink></li>
                            <li className="mainMenuListItem"><ScrollLink to="advSlide" smooth={true}><FormattedMessage id='advantages' defaultMessage='Advantages'/></ScrollLink></li>
                            <li className="mainMenuListItem"><ScrollLink to="footer" smooth={true}><FormattedMessage id='get-in-touch' defaultMessage='Get in touch'/></ScrollLink></li>
                        </ul>
                        <ul className='flagsList'>
                            <li><a href="/ua/"><img src={FlagUa} alt=""/></a></li>
                            <li><a href="/"><img src={FlagEn} alt=""/></a></li>
                            {/* <li><a href="/ru/"><img src={FlagRu} alt=""/></a></li> */}
                        </ul>
                    </div>
                </div>
            </CSSTransition>
        </div>
    )
}

export default MenuComponent