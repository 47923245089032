import PlaceholderText from "./get-placeholder-text.component";

export default function validate(values) {
    let errors = {};
    if (!values.email) {
        errors.email = 'error-email-required';
    } else if (!/\S+@\S+\.\S+/.test(values.email)) {
        errors.email = 'error-email-invalid';
    }
    if (!values.name) {
        errors.name = 'error-name';
    }
    if (!values.message) {
        errors.message = "error-message";
    }
    return errors;
};