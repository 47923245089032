import { Link } from "gatsby"
import React from "react"
import Logo from "../../assets/img/logo_leads.svg"
import MenuComponent from "./menu-button/menu.component";
import {Link as ScrollLink} from "react-scroll";
import LinkTranslated from "../localization/link-translated.component";
import {FormattedMessage} from "react-intl";
import LanguageSelector from "./language-selector/language-selector.component";

const HeaderComponent = () => (
  <header className='mainHeader'>
      <div className="mainContainer fluid">
          <div className="headerWrapper">
              <div className="logoHolder">
                  <LinkTranslated href='/'><img src={Logo} alt="Huboxt" style={{width: '108px'}} className='logoImg'/></LinkTranslated>
              </div>
              <ul className="desktopMenu">
                  <li className="dmItem">
                    <ScrollLink to="aboutSlide" smooth={true} offset={120}>
                        <FormattedMessage id='about' defaultMessage='About'/>
                    </ScrollLink>
                  </li>
                  <li className="dmItem"><ScrollLink to="keySlide" smooth={true}><FormattedMessage id='key-features' defaultMessage='Key features'/></ScrollLink></li>
                  <li className="dmItem"><ScrollLink to="priceSlide" smooth={true}><FormattedMessage id='pricing' defaultMessage='Pricing'/></ScrollLink></li>
                  <li className="dmItem"><ScrollLink to="advSlide" smooth={true}><FormattedMessage id='advantages' defaultMessage='Advantages'/></ScrollLink></li>
              </ul>
              <LanguageSelector/>
              <div className="desktopContactHolder">
                  <ScrollLink to="footer" smooth={true} className='btn btnPrimary'><FormattedMessage id='get-in-touch' defaultMessage='Get in touch'/></ScrollLink>
              </div>
              <MenuComponent/>
          </div>
      </div>
  </header>
)

export default HeaderComponent
