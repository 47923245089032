import React, {useEffect} from "react"

const ChatBtn = () => {
    useEffect(() => {
        const script = document.createElement('script');
        script.src = "//code.tidio.co/g5msmexvszh6mb9vpxq5b7dxxuqylnlu.js";
        script.async = true;
        document.body.appendChild(script);
        return () => {
            document.body.removeChild(script);
        }
    }, [])

  return (
    <></>
  )
}

export default ChatBtn