import { Link } from "gatsby"
import React from "react"
import ContactFormComponent from "../contact-form/contact-form.component";
import Logo from '../../assets/img/logo_leads.svg'
import {Link as ScrollLink} from "react-scroll";
import {FormattedMessage} from "react-intl";

const FooterComponent = () => (
    <footer className='mainFooter' id='footer'>
        <div className="mainContainer">
            <div className="footerContentPart">
                <div className="footerWrapper">
                    <div className="textPart">
                        <div className="logoHolder">
                            <img src={Logo} alt="" width={'108px'}/>
                        </div>
                        <div className="text">
                            <FormattedMessage id='deliver-pocket' defaultMessage="Deliver a message right to the customer's pocket"/>
                        </div>
                        <div className="formPart mobile">
                            <ContactFormComponent/>
                        </div>
                        <ul className="footerMenu">
                            <li className="fmItem">
                                <ScrollLink to="aboutSlide" smooth={true} offset={120}>
                                    <FormattedMessage id='about' defaultMessage='About'/>
                                </ScrollLink>
                            </li>
                            <li className="fmItem">
                                <ScrollLink to="keySlide" smooth={true}>
                                    <FormattedMessage id='key-features' defaultMessage='Key Features'/>
                                </ScrollLink>
                            </li>
                            <li className="fmItem">
                                <ScrollLink to="advSlide" smooth={true}>
                                    <FormattedMessage id='advantages' defaultMessage='Advantages'/>
                                </ScrollLink>
                            </li>
                        </ul>
                    </div>
                    <div className="formPart desktop">
                        <ContactFormComponent/>
                    </div>
                </div>
            </div>
            <div className="copyrightPart">
                <FormattedMessage id='copyright' defaultMessage='© Leads 2022. All rights reserved.'/>
            </div>
        </div>
    </footer>
)

export default FooterComponent