/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import "../../assets/scss/style.scss"
import "typeface-montserrat";

import HeaderComponent from "../header/header.component"
import FooterComponent from "../footer/footer.component"
import ChatBtn from "../chat-btn/chat-btn.component";

const Layout = ({children}) => {

    return (
        <>
            <HeaderComponent/>
            <main>{children}</main>
            <FooterComponent/>
            <ChatBtn/>
        </>
    )
}

Layout.propTypes = {
    children: PropTypes.node.isRequired,
}

export default Layout
