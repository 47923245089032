import React, { useEffect, useRef, useState } from "react"
import { useIntl } from "react-intl"
import FlagUa from "../../../assets/img/flag-uk.svg"
import FlagEn from "../../../assets/img/flag-english.svg"
import FlagRu from "../../../assets/img/flag-ru.svg"
import { CSSTransition } from "react-transition-group"

const LanguageSelector = () => {
  const ref = useRef()
  const [langOpen, setLangOpen] = useState(false)
  const language = useIntl().locale

  useEffect(() => {
    let storedLanguage = localStorage.getItem("language")
    if (storedLanguage) return
    let locale
    if (navigator.languages !== undefined) locale = navigator.languages[0]
    else locale = navigator.language
    if (locale === "en") {
      localStorage.setItem("language", "en")
      document.location.href = "/en/"
    } else {
      localStorage.setItem("language", "ua")
      document.location.href = "/"
    }
  }, [])

  useEffect(() => {
    const checkIfClickedOutside = e => {
      // If the menu is open and the clicked target is not within the menu,
      // then close the menu
      if (langOpen && ref.current && !ref.current.contains(e.target)) {
        setLangOpen(false)
      }
    }
    document.addEventListener("mousedown", checkIfClickedOutside)
    return () => {
      // Cleanup the event listener
      document.removeEventListener("mousedown", checkIfClickedOutside)
    }
  }, [langOpen])

  return (
    <div className="languageSelector" ref={ref}>
      <div className="lsTitle" onClick={() => setLangOpen(!langOpen)}>
        {language === "ua" ? (
          <div>
            <img src={FlagUa} alt="" />
            Українська
          </div>
        ) : null}
        {language === "en" ? (
          <div>
            <img src={FlagEn} alt="" />
            English
          </div>
        ) : null}
        {/* {language === "ru" ? (
          <div>
            <img src={FlagRu} alt="" />
            Русский
          </div>
        ) : null} */}
      </div>
      <CSSTransition
        in={langOpen}
        timeout={300}
        unmountOnExit
        classNames="mobile-menu"
      >
        <ul className="lsMenu">
          <li className="lsmItem">
            <a href="/">
              <img src={FlagUa} alt="" />
              Українська
            </a>
          </li>
          <li className="lsmItem">
            <a href="/en/">
              <img src={FlagEn} alt="" />
              English
            </a>
          </li>
          {/* {<li className="lsmItem">
            <a href="/ru">
              <img src={FlagRu} alt="" />
              Русский
            </a>
          </li>} */}
        </ul>
      </CSSTransition>
    </div>
  )
}

export default LanguageSelector
